import React from "react";
import HeroBanner from "./../../assets/img/hero-woman.png";

class Visions extends React.Component {
  render() {
    return (
      <div id="visions" className="visions">
        <section id="hero" class="d-flex align-items-center">
          <div class="container">
            <div class="row gy-4">
              <div class="col-lg-6 order-2 order-lg-1 d-flex flex-column justify-content-center">
                <h1>Chameleon Sample</h1>
                <h2>
                  Web ini dibuat hanya untuk pembelajaran saya seorang. Bukan digunakan sebagai komersil
                </h2>
                <div>
                  <a href="#about" class="btn-get-started scrollto">
                    Simple Button
                  </a>
                </div>
              </div>
              <div class="col-lg-6 order-1 order-lg-2 hero-img">
                <img
                  src={HeroBanner}
                  class="img-fluid animated"
                  alt=""
                />
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default Visions;
